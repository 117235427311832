/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-09-22 10:26:20
 * @LastEditors: pj
 * @LastEditTime: 2023-02-27 16:39:56
 */
/**
 * 所有的请求都放在
 */
export default {
  listWorks: '/api/social/opus/getOpusList', // 作品列表
  sendSms: '/api/user/send/sendSms', // 验证码
  sighIn: '/api/user/login/registeredUser', // 注册
  sighUp: '/api/user/login/login', //登录
  getpass_Api: "/api/user/login/getpass", // 拿到公钥后验证码发去加密
  getPublicKey_Api: "/api/user/login/getPublicKey", // 请求公钥
  getIdentityWord: '/api/user/user/getIdentityWord', // 获取身份证
  addImageApi: '/distapi/file/file/uploadImage', // 上传图片
  getResultOfIdCardApi: '/api/user/user/saveIdCard', // 提取身份证信息
  userLowAuth: '/api/user/user/userLowAuth', // 绑定身份证
  saveOpus: '/api/social/opus/saveOpus', // 上传作品
  isRelease: 'api/social/opus/isRelease', //获取是否上传
  getAgreement: '/api/system/agreement/getAgreement',
  applyFormDate: '/api/user/apply/apply'
}